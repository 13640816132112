<template>
    <div class="profile flex flex-col items-center py-0 ">
        <div class="main-content w-full max-w-[1920px]">
            <div class="main-container flex flex-col md:flex-row gap-0 md:gap-5 lg:gap-10 xl:gap-20 px-0 py-0 md:px-5 md:py-14 lg:px-20 lg:py-14">
                <div class="babe-item w-full flex flex-col flex-nowrap gap-4 overflow-hidden relative">
                    <div class="back-button-wrapper absolute z-30 top-4 left-4">
                        <button @click="goBack" class="back-button w-[34px] h-[34px] rounded-[50%] bg-white border border-[#ECECEC] flex flex-col justify-center items-center shadow-[0p_x2px_5px_0px_rgba(0, 0, 0, 0.10)]">
                            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.58074 11.9545L1.57681 6.88562C1.47587 6.78333 1.41916 6.64462 1.41916 6.49998C1.41916 6.35535 1.47587 6.21663 1.57681 6.11435L6.58074 1.04544" stroke="#222222" stroke-width="1.4"/>
                            </svg>
                        </button>
                    </div>
                    <div v-if="user.images.length > 1" class="swiper-container relative">
                        <div class="swiper-wrapper">
                            <div v-for="(image, index) in user.images" :key="index" class="swiper-slide relative h-[350px] md:h-[400px] lg:h-[500px] md:rounded-3xl md:overflow-hidden">
                                <img :src="image.name" :alt="user.first_name" class="babe-img w-full object-cover object-center h-full">
                                <div class="absolute z-10 bottom-4 right-4 rounded-[20px] bg-white flex flex-col py-2 px-[18px]">
                                    <span class="image-number text-black text-sm font-medium leading-4">{{ index + 1 }} / {{ user.images.length }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="babe-img-wrapper flex flex-col items-center h-[350px] md:h-[400px] lg:h-[500px] md:rounded-3xl md:overflow-hidden">
                        <img v-for="(image, index) in user.images" :key="index" :src="image.name" :alt="user.first_name" class="babe-img w-full object-cover object-center h-full">
                    </div>
                </div>
                <div class="babe-details w-full flex flex-col gap-5 md:gap-6 px-5 pt-5 pb-10 md:p-0">
                    <div class="babe-detail babe-main-detail flex flex-col gap-0 items-start">
                        <h2 class="babe-name font-bold text-[28px] leading-normal text-body-text">{{ user.first_name }}, <span v-if="user.dob" clas="age">{{ computeAge(user) }}</span></h2>
                        <div class="babe-address text-sm font-semibold md:text-xl leading-normal text-[#7B7B7B]"><span v-if="user.city">{{ user.city }},</span><span v-else></span> {{ user.country }}</div>
                        <div class="babe-reviews-ratings flex flex-row gap-2">
                            <div v-if="user.reviews" class="babe-ratings flex flex-row gap-1 justify-end items-center text-sm md:text-xl font-medium leading-normal ">
                                <img src="@/assets/star.svg" > <span>4.9</span>
                            </div>
                            <div v-if="user.reviews" class="babe-ratings flex flex-row gap-1 justify-end items-center text-sm md:text-xl font-medium leading-normal text-[#7b7b7b]">
                                <span>{{ user.reviews }} reviews</span>
                            </div>
                        </div>
                    </div>
                    <div class="sep h-[1px] w-full bg-[#D1D1D1]"></div>
                    <div v-if="user.superbabe != 0" class="babe-superbaba flex flex-row flex-wrap gap-5 place-items-start">
                        <div class="babe-super-babe-icon mt-2">
                            <img src="@/assets/diamond.svg" >
                        </div>
                        <div class="babe-super-babe-texts flex flex-col flex-nowrap items-start">
                            <h5 class="text-lg font-bold">Superbabe</h5>
                            <p class="mb-0 text-sm md:text-base text-[#7B7B7B]">This is a rare find! She is often fully booked.</p>
                        </div>
                    </div>
                    <div v-if="user.superbabe != 0" class="sep h-[1px] w-full bg-[#D1D1D1]"></div>
                    <CategoryList :babeActivities="user.activities" :nonClickableActivities="user.activities" />
                    <div class="sep h-[1px] w-full bg-[#D1D1D1]"></div>
                    <div v-if="user.bio" class="babe-bio flex flex-col items-start text-left">
                        <div class="mb-3 text-sm md:text-base" v-html="user.bio"></div>
                    </div>
                    <div v-if="user.bio" class="sep h-[1px] w-full bg-[#D1D1D1]"></div>
                    <div class="babe-additional-info flex flex-col gap-5 items-start">
                        <h3 class="body-text font-bold text-[22px] leading-normal">More About Me</h3>
                        <div class="babe-infos flex flex-col md:flex-row flex-wrap items-start gap-3 w-full">
                            <div v-if="user.nationality !== ''" class="babe-info flex flex-row gap-4 w-full">
                                <div class="babe-info-label whitespace-nowrap flex flex-row flex-nowrap items-start gap-[10px] md:w-32 text-base font-semibold">
                                    <div class="babe-info-img-wrap w-[24px] h-[24px] flex flex-col justify-center items-center">
                                        <img src="@/assets/nationality.svg" class="mt-[2px]"> 
                                    </div>
                                    <span>Nationality</span>
                                </div>
                                <div class="babe-info-val flex flex-col items-start w-1/2 ml-auto sm:ml-5 text-base font-normal">
                                    {{ user.nationality }}
                                </div>
                            </div>
                            <div v-if="user.height !== ''" class="babe-info flex flex-row gap-4 w-full">
                                <div class="babe-info-label whitespace-nowrap flex flex-row flex-nowrap items-start gap-[10px] md:w-32 text-base font-semibold">
                                    <div class="babe-info-img-wrap w-[24px] h-[24px] flex flex-col justify-center items-center">
                                        <img src="@/assets/height.svg" class="mt-[2px]">
                                    </div>
                                    <span>Height</span>
                                </div>
                                <div class="babe-info-val flex flex-col items-start w-1/2 ml-auto sm:ml-5 text-base font-normal">
                                    {{ user.height }}cm
                                </div>
                            </div>
                            <div v-if="user.weight !== ''" class="babe-info flex flex-row gap-4 w-full">
                                <div class="babe-info-label whitespace-nowrap flex flex-row flex-nowrap items-start gap-[10px] md:w-32 text-base font-semibold">
                                    <div class="babe-info-img-wrap w-[24px] h-[24px] flex flex-col justify-center items-center">
                                        <img src="@/assets/weight.svg" class="mt-[2px]">
                                    </div>
                                    <span>Weight</span>
                                </div>
                                <div class="babe-info-val flex flex-col items-start w-1/2 ml-auto sm:ml-5 text-base font-normal">
                                    {{ user.weight }}lbs
                                </div>
                            </div>
                            <div v-if="user.interests !== ''" class="babe-info flex flex-row gap-4 w-full">
                                <div class="babe-info-label whitespace-nowrap flex flex-row flex-nowrap items-start gap-[10px] md:w-32 text-base font-semibold">
                                    <div class="babe-info-img-wrap w-[24px] h-[24px] flex flex-col justify-center items-center">
                                        <img src="@/assets/interests.svg" class="mt-[2px]">
                                    </div>
                                    <span>Interests</span>
                                </div>
                                <div class="babe-info-val flex flex-col items-start w-1/2 ml-auto sm:ml-5 text-base font-normal">
                                    <span v-for="(interest, index) in user.interests" :key="index">
                                        {{ interest }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="user.foods !== ''" class="babe-info flex flex-row gap-4 w-full">
                                <div class="babe-info-label whitespace-nowrap flex flex-row flex-nowrap items-start gap-[10px] md:w-32 text-base font-semibold">
                                    <div class="babe-info-img-wrap w-[24px] h-[24px] flex flex-col justify-center items-center">
                                        <img src="@/assets/food.svg" class="mt-[2px]">
                                    </div>
                                    <span>Foods</span>
                                </div>
                                <div class="babe-info-val flex flex-col items-start w-1/2 ml-auto sm:ml-5 text-base font-normal">
                                    <span v-for="(food, index) in user.foods" :key="index">
                                        {{ food }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="user.drinks !== ''" class="babe-info flex flex-row gap-4 w-full">
                                <div class="babe-info-label whitespace-nowrap flex flex-row flex-nowrap items-start gap-[10px] md:w-32 text-base font-semibold">
                                    <div class="babe-info-img-wrap w-[24px] h-[24px] flex flex-col justify-center items-center">
                                        <img src="@/assets/drink.svg" class="mt-[2px]">
                                    </div>
                                    <span>Drinks</span>
                                </div>
                                <div class="babe-info-val flex flex-col items-start w-1/2 ml-auto sm:ml-5 text-base font-normal">
                                    <span v-for="(drink, index) in user.drinks" :key="index">
                                        {{ drink }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sep h-[1px] w-full bg-[#D1D1D1]"></div>
                    <div class="babe-cancelation flex flex-col items-start text-left">
                        <h3 class="font-bold text-[22px] leading-relaxed">Cancellation Policy</h3>
                        <p class="text-sm md:text-base">Reservations are non-refundable 2 days before the set date.</p>
                        <p class="mb-3 text-sm md:text-base">Review our full cancellation policy here.</p>
                    </div>
                    <div class="sep h-[1px] w-full bg-[#D1D1D1]"></div>
                    <div class="babe-cancelation flex flex-col items-start text-left">
                        <h3 class="font-bold text-[22px] leading-relaxed">Safety Rules</h3>
                        <p class="text-sm md:text-base">Location details must be discussed and agreed upon ahead of time.</p>
                        <p class="mb-3 text-sm md:text-base">To protect your payment, never transfer money or communicate outside the wesite.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="babe-footer flex flex-col items-center w-full p-5 bg-white shadow-[0px_-5px_10px_0px_rgba(0,0,0,0.05)] sticky bottom-0 z-50">
            <div class="babe-footer-inner flex flex-row gap-6 items-center w-full max-w-[1920px]">
                <div class="babe-footer-left flex flex-col items-start whitespace-nowrap text-sm md:text-lg">
                    <div class="babe-footer-left-inner flex flex-row items-center gap-1">
                        <span class="font-bold text-lg md:text-2xl">SGD{{ user.price }}</span><span class="text-sm md:text-lg">{{ user.date_mode }}</span>
                    </div>
                    {{ formattedDateRange }}
                </div>
                <div class="babe-footer-right flex flex-col ml-auto w-full max-h-28 md:w-80">
                    <a href="https://wa.me/60123456789" target="_blank" class="flex flex-col py-3 px-6 bg-[#E9394E] text-white text-sm md:text-lg font-bold leading-normal rounded-[200px] border border-[#E9394E] whitespace-nowrap">Book Now</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import axios from 'axios';
// import userData from '../assets/users.json';

import CategoryList from './CategoryList.vue'

export default {
    name: 'BabeDetail',
    components: {
        CategoryList,
    },
    data() {
        return {
            user: {
                images: []
            },
        }
    },
    computed: {
        formattedDateRange() {
            const startDate = new Date(this.user.availability_start);
            const endDate = new Date(this.user.availability_end);

            const startMonth = startDate.toLocaleDateString('default', { month: 'short' });
            const endMonth = endDate.toLocaleDateString('default', { month: 'short' });

            if ( startDate.getMonth() === endDate.getMonth() ) {
                return `${startMonth} ${startDate.getDate()} - ${endDate.getDate()}, ${endDate.getFullYear()}`;
            } else {
                return `${startMonth} ${startDate.getDate()} - ${endMonth} ${endDate.getDate()}, ${endDate.getFullYear()}`;
            }
        }
    },
    mounted() {
        if (this.user.image && this.user.images.length > 1) {
            this.initSwiper();
        }
    },
    async created() {
        try {
            const userId = this.$route.params.id;
            // const response = await axios.get(`https://omgb-api.sgestaging.com/wp-json/custom/v1/babes/${userId}`);
            // const response = await axios.get(`/api/wp-json/custom/v1/babes/${userId}`);
            // const response = await axios.get(`http://localhost:3000/admin/babes/${userId}`);
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/admin/babes/${userId}`);
            this.user = response.data;

            if (this.user.images && this.user.images.length > 1) {
                this.initSwiper();
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    },
    methods: {
        initSwiper() {
            setTimeout(() => {
                console.log('Initializing Swiper');
                new Swiper('.swiper-container', {
                        slidesPerView: 1,
                    }
                );
            }, 1000);
        },
        computeAge(user) {
            const birthYear = new Date(user.dob).getFullYear();
            const currentYear = new Date().getFullYear();
            const age = currentYear - birthYear;
            console.log('boy: ' + birthYear)
            console.log('current year: ' + currentYear)
            console.log(age)
            return age;
        },
        goBack() {
            this.$router.go(-1);
        }
    }
}
</script>
<style>
    
</style>
<style scoped>

    .swiper-slide {
        height: 312px!important;
    }
    .categories-wrap {
        position: unset;
        box-shadow: none;
        border-top: 0;
    }
    
    @media only screen and (min-width: 768px) {
        .swiper-slide {
            height: 400px!important;
        }
        .babe-item {
            max-width: calc(40% - (20px * 1/2));
        }
        .babe-details {
            max-width: calc(60% - (20px * 1/2));
        }
        .babe-info {
            max-width: calc(50% - (20px * 1 / 2));
        }
        .babe-infos {
            column-count: 2;
            column-gap: 0;
            flex: 1;
            display: initial;
        }
        .babe-infos .babe-info {
            /* page-break-inside: avoid;
            break-inside: avoid;
            -webkit-column-break-inside: avoid; */
            max-width: unset;
        }
        .babe-infos .babe-info + .babe-info {
            margin-top: 20px;
        }
        .babe-info-val {
            page-break-inside: avoid;
            break-inside: avoid;
            -webkit-column-break-inside: avoid;
        }
    }
    @media only screen and (min-width: 1024px) {
        .swiper-slide {
            height: 500px!important;
        }
    }
    
</style>