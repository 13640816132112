import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import AboutPage from '../components/About.vue';
import BabeDetail from '../components/BabeDetial.vue';

const routes = [
  { path: '/', component: HomePage, name: 'homePage' },
  { path: '/about', component: AboutPage },
  { path: '/babe/:id/:firstName', 
    name: 'babeDetail', 
    component: BabeDetail },
  { path: '/category/:id/:name' }
];

const router = createRouter({
  mode:'history', 
  history: createWebHistory(),
  routes,
});

export default router;
