<template>
  <CategoryList @selectionChanged="updateSelectedCategories" />
  <div class="flex flex-col items-center">
    <div class="main px-5 py-10 md:px-5 md:py-14 lg:px-20 lg:py-14 bg-white w-full max-w-[1920px]">
      <div v-if="selectedDate" class="mb-6 md:mb-20 flex flex-col items-center">
        <h2 class="text-center font-bold text-xl md:text-2xl">Babes available on {{ formattedDate(selectedDate) }}</h2>
      </div>
      <div class="flex gap-5 flex-nowrap flex-col md:flex-row md:flex-wrap md:justify-center">
          <transition v-for="babe in babes" :key="babe.id" name="babe-list" tag="div">
            <BabeItem v-if="babe" :babe="babe" />
          </transition>
      </div>
      <div class="loading" v-if="isLoading">
          <div class="loading-ring"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex';
import CategoryList from './CategoryList.vue';
import BabeItem from './BabeItem.vue';

const debounce = (func, delay) => {
  let inDebounce;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      func.apply(context, args),
      delay
    });
  }
}

export default {
  name: "HomePage",
  components: {
    CategoryList,
    BabeItem,
  },
  computed: {
    selectedDate() {
      return this.$store.state.selectedDate;  // Accessing selected date from Vuex
    },
    numberOfPages() {
      return Math.ceil(this.totalPage / this.babesLimit);
    }
  },
  data() {
    return {
      babes: [],
      selectedCategories: [],
      isLoading: true,
      babesLimit: 8,
      imageLoadLimit: 6,
      paged: 1,
      totalPage: null,
    };
  },
  async mounted() {
    this.fetchAndLoadBabes(10, 1)
    window.addEventListener('scroll', debounce(this.scroll, 200));
  },
  watch: {
    paged(newPaged, oldPaged) {
      if (newPaged !== oldPaged) {
        this.fetchAndLoadBabes();
      }
    },
    selectedCategories(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.babes = []; // Clear existing babes for a complete reload
        this.paged = 1;
        this.fetchAndReloadBabes(true);
      }
    },
    selectedDate(newDate, oldDate) {
      if (newDate !== oldDate) {
        this.babes = []; // Clear existing babes for a complete reload
        this.paged = 1;
        this.fetchAndReloadBabes(true);
      }
    }
  },
  methods: {
    async fetchBabes(babesLimit, paged) {
      try {
        const categories = this.selectedCategories.join(',');
        let url = `${process.env.VUE_APP_API_URL}/admin/babes?status=publish&limit=${babesLimit}&page=${paged}`;

        if (categories) {
          url += `&activities=${encodeURIComponent(categories)}`;
        }
        if (this.selectedDate) {
          url += `&selected_date=${encodeURIComponent(this.selectedDate)}`;
        }

        console.log('Fetching URL:', url);
        const response = await fetch(url);
        const json = await response.json();
        console.log('Fetched data:', json);
        return json;
      } catch (error) {
        console.error('Failed to fetch babes:', error);
        return { babes: [], total: 0 }; // Return an empty response on failure
      }
    },
    formattedDate(dateStr) {
      const dateParts = dateStr.split('/'); // Split the date string by '/'
      // Construct a new Date object (month is 0-indexed so subtract 1)
      const date = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
      // Format the date to 'long' format
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    async fetchAndLoadBabes() {
      const response = await this.fetchBabes(this.babesLimit, this.paged);
      this.babes = this.babes.concat(response.babes);
      this.totalPage = response.total;
      this.isLoading = false;
      console.log('this babes:', this.babes);
      console.log(this.totalPage)
    },
    async fetchAndReloadBabes(reload = false) {
      if (reload) {
        this.babes = []; // Clear existing babes for a complete reload
        this.paged = 1;
      }

      const response = await this.fetchBabes(this.babesLimit, this.paged);

      if (reload) {
        // This is a complete reload, replace babes
        this.babes = response.babes;
      } 

      console.log(this.paged)
      this.totalPage = response.total;
      this.isLoading = false;
    },
    async addMoreBabes() {
      this.paged += 1;
    },
    updateSelectedCategories(selectedCategories) {
      this.selectedCategories = selectedCategories;
    },
    scroll() {
       if (this.paged <= this.numberOfPages) {
        let scrolledToBottom = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (scrolledToBottom) {
          this.isLoading = true;
          debounce(this.addMoreBabes, 300)();
        }
      }
    }
  }
};
</script>

<style scoped>
  .loading {
    text-align: center;
  }
  .loading-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
    padding-top: 10px;
  }
  .loading-ring:after {
    content: '';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid blue;
    border-color: blue transparent blue transparent;
    animation: ani-loading-ring 1.2s linear infinite;
  }
  @keyframes ani-loading-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .babe-list-enter-active, .babe-list-leave-active {
    transition: opacity 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .babe-list-enter, .babe-list-leave-to /* .babe-list-leave-active in <2.1.8 */ {
    opacity: 0;
  }

  /* You can also add styles for move transitions if your list is dynamic and items can change positions */
  .babe-list-move {
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
</style>