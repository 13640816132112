<template>
    <header class="bg-white w-full flex flex-col items-center py-5 px-4 relative z-50">
        <div class="container flex justify-between items-center w-full max-w-[1920px]">
            <div class="flex items-center">
                <router-link to="/">
                    <img src="@/assets/logo-header.svg" alt="" class="max-w-100% h-[24px] md:h-[40px]">
                </router-link>
            </div>
            <div class="flex items-center gap-[6px]">
               
                <!-- <button class="text-body-text py-1 px-3 font-body-text text-sm leading-normal font-semibold">Login</button>
                <button class="text-body-text bg-gold py-1 px-3 leading-normal font-body-text text-sm rounded-[60px] font-semibold">Sign Up</button> -->
                <div class="relative" x-data="{ open: false }" x-on:close-datepicker.window="open = false">
                     <button x-on:click="open = !open" class="border border-[#DDD] rounded-[60px] py-0 px-0 flex items-center justify-center gap-2 flex-row font-bold w-[40px] h-[40px] md:max-w-fit md:max-h-fit md:py-4 md:px-4 md:w-auto md:h-auto">
                        <img src="@/assets/calendar-outline.svg" class="w-[24px] h-[24px]">
                        <span class="hidden md:block">Find by Date</span>
                    </button>
                    <!-- <button x-on:click="open = !open" class="border border-[#DDD] rounded-[60px] py-1 px-2 flex items-center gap-2">
                        <img src="@/assets/user.svg" >
                        <img src="@/assets/ham.svg" >
                    </button> -->
                    
                    <div x-show="open" x-on:click.away="open = false" class="absolute right-0 bg-white shadow rounded-lg">
                        <!-- <a href="#" class="block px-4 text-body-text">Profile</a>
                        <a href="#" class="block px-4 text-body-text">Settings</a>
                        <hr class="my-2 border border-gray-300">
                        <a href="#" class="block px-4 text-body-text">Logout</a> -->
                         <v-date-picker
                            is-inline
                            v-model="date"
                            @update:modelValue="handleDateChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import 'v-calendar/dist/style.css';

export default {
    name: "HeaderSection",
    data() {
        return {
            date: null, // default date
            attributes: [
                {
                    highlight: {
                        backgroundColor: '#ffecb3',
                    },
                    dates: new Date(),
                },
            ],
            showDatePicker: false, // Control visibility of the date picker
        };
    },
    methods: {
        handleDateChange(date) {
            // Format the date to m/d/y
            const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            // Emit the formatted date
            // this.$emit('date-change', formattedDate)

            if (this.$route.path !== '/') {
                // Redirect to the homepage
                this.$router.push('/');
                this.$store.dispatch('updateSelectedDate', formattedDate);
            } else {
                this.$store.dispatch('updateSelectedDate', formattedDate);
            }

            console.log('Selected DATE', formattedDate);
            
        },
        toggleDatePicker() {
            this.showDatePicker = !this.showDatePicker; // Toggle the visibility
        },
    }
}
</script>