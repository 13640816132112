import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    babes: [],
    isLoading: false,
    page: 1,
    hasMoreData: true,
    selectedDate: null,
  },
  mutations: {
    setBabes(state, babes) {
      state.babes = state.babes.concat(babes);
      state.isLoading = false;
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
    setHasMoreData(state, hasMoreData) {
      state.hasMoreData = hasMoreData;
    },
    incrementPage(state) {
      state.page += 1;
    },
    setSelectedDate(state, date) {
      state.selectedDate = date;
    }
  },
  actions: {
    async fetchBabes({ commit, state }) {
      if (state.isLoading || !state.hasMoreData) return; // Prevent multiple simultaneous requests and check for more data

      commit('setLoading', true);
      try {
        const response = await axios.get(`/api/wp-json/custom/v1/babes?page=${state.page}`);
        // const response = await axios.get(`https://omgb-api.sgestaging.com/wp-json/custom/v1/babes?page=${state.page}`);
        
        // Check if there is more data
        const hasMoreData = response.data.length > 0;
        commit('setBabes', response.data);
        commit('setHasMoreData', hasMoreData);
        // Increment the page only if there is more data
        if (hasMoreData) {
          commit('incrementPage');
        }
      } catch (error) {
        console.error('Error fetching babes:', error);
        commit('setLoading', false);
      }
    },
    updateSelectedDate({ commit }, date) {
      commit('setSelectedDate', date);
    }
  },
  getters: {
    getBabes: (state) => state.babes,
    isLoading: (state) => state.isLoading,
  },
});
