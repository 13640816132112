import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store'; // Import your Vuex store
import VCalendar from 'v-calendar'
import './assets/styles/tailwind.css';

createApp(App)
  .use(router)
  .use(store) // Use the Vuex store
  .use(VCalendar)
  .mount('#app');
