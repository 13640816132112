<template>
    <div class="categories-wrap gap-5 bg-white sticky top-[120px] md:top-[138px] z-30">
        <div class="categories flex gap-5 overflow-scroll px-5 pt-4 pb-1 justify-center">
            <div class="flex flex-col flex-nowrap items-center" v-for="category in filteredActivities" :key="category.id">
                <!-- Checkbox for each category -->
                <input type="checkbox" :id="`category-${category.id}`" v-model="selectedCategories" :value="category.name" class="hidden">
                <label :for="`category-${category.id}`" :class="{ 'non-clickable': isNonClickable(category) }" class="flex flex-col items-center gap-2">
                    <img :src="getFullImageUrl(category.image)" alt="" class="w-[28px] h-[28px]">
                    <h6 class="text-body-text text-xs font-semibold">{{ category.name }}</h6>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "CategoryList",
    props: ['babeActivities', 'nonClickableCategories'],
    data() {
        return {
            categories: [],
            selectedCategories: []
        }
    },
    mounted() {
        
    },
    computed: {
        filteredActivities() {
            if ( !this.babeActivities || this.babeActivities.length === 0 ) {
                return this.categories
            }
            return this.categories.filter(category => this.babeActivities.includes(category.name));
        }
    },
    async created() {
        try {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/admin/activities`);
            
            this.categories = response.data;
            console.log(this.categories)
        } catch(error) {
            console.error('Error fetching data:', error);
        }
    },
    methods: {
        isNonClickable(category) {
            return this.nonClickableCategories && this.nonClickableCategories.includes(category.name);
        },
        getFullImageUrl(path) {
            const domain = `${process.env.VUE_APP_API_URL}`; // Your domain
            return `${domain}${path}`;
        },
    },
    watch: {
        selectedCategories(newVal) {
            // Emit an event with the updated selected categories
            this.$emit('selectionChanged', newVal);
        },
    },
}
</script>

<style scoped>
    .categories-wrap {
        border-top: 1px solid rgba(222, 222, 222, 1);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .categories::-webkit-scrollbar {
        display: none;
    }
    .categories {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .profile .categories input + label {
        cursor: none;
        pointer-events: none;
    }
    .categories-wrap:before,
    .categories-wrap:after {
        content: '';
        position: absolute;
        top: 0;
        z-index: 2;
        height: 100%;
        width: 30px;
    }
    .categories-wrap:before {
        left: 0;
        background: rgb(234,234,234);
        background: linear-gradient(90deg, rgba(234,234,234,1) 0%, rgba(234,234,234,0) 100%);
    }
    .categories-wrap:after {
        right: 0;
        background: rgb(234,234,234);
        background: linear-gradient(270deg, rgba(234,234,234,1) 0%, rgba(234,234,234,0) 100%);
    }
    .profile .categories-wrap:before,
    .profile .categories-wrap:after {
        display: none;
    }
    input + label {
        border-bottom: 2px solid transparent;
        cursor: pointer;
    }
    input:checked + label {
        border-bottom-color: black;
    }
    @media only screen and (min-width: 768px) {
        .profile .babe-details .categories-wrap .categories {
            justify-content: flex-start;
        }
    }
    @media only screen and (max-width: 767px) {
        .profile .babe-details .categories-wrap .categories {
            flex-flow: row wrap;
            justify-content: flex-start;
            gap: 15px;
            padding-left: 0;
            padding-right: 0;
        }
        .profile .babe-details .categories-wrap .categories > div {
            width: 100%;
            max-width: calc(50% - (15px * 1 / 2));
            flex: 0 0 calc(50% - (15px * 1 / 2));
            flex-flow: column nowrap;
            align-items: flex-start;
        }
        .profile .babe-details .categories-wrap .categories > div label {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 8px;
        }
        .profile .babe-details .categories-wrap .categories > div label img {
            width: 32px;
            height: 32px;
        }
        .profile .babe-details .categories-wrap .categories > div label h6 {
            font-size: 16px;
            line-height: 16px;
        }
    }
</style>