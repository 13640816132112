<template>
    <div class="top-bar py-2 px-4 bg-[#29FFFF] flex flex-col items-center">
        <a href="#" class="top-bar-link font-body-text text-sm font-semibold leading-6 underline">Become a Babe!</a>
    </div>
</template>

<script>
export default {
    name: 'TopBar'
}
</script>