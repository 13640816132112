<template>
    <div class="footer bg-[#fff] border-t border-[#DEDEDE]  py-5 px-5 flex flex-col gap-3 items-center md:flex-row md:justify-between md:items-center">
        <router-link to="/">
            <img src="@/assets/footer-logo.svg" alt="" height="18">
        </router-link>
        <div class="copyright-texts text-center">
            <p class="text-sm text-[#707070]"><span class="text-black">© 2024 OmgBabe.com — All rights reserved.</span> This site is protected by reCAPTCHA and the Google Privacy Policy. Terms and Conditions apply.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterSection"
}
</script>