<template>
    <div>
        <h1>About Us</h1>
    </div>
</template>

<script>
export default {
    name: "AboutPage",
};
</script>