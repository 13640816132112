<template>
  <div class="content" :class="routeClass">
    <div class="header-wrapper sticky top-0 z-50">
      <TopBar />
      <HeaderSection />
    </div>
    <router-view />
    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from './components/HeaderSection.vue'
import FooterSection from './components/FooterSection.vue'
import TopBar from './components/TopBar.vue'

export default {
  name: "App",
  components: {
    TopBar,
    HeaderSection,
    FooterSection
  },
  provide() {
    return {
      setSelectedDate: this.setSelectedDate,
      selectedDate: this.selectedDate
    };
  },
  data() {
    return {
      selectedDate: null
    };
  },
computed: {
    routeClass() {
      // This returns a class based on the current route's name
      return this.$route.name.replace(/\s+/g, '-').toLowerCase();
    }
  },
  methods: {
    setSelectedDate(date) {
      this.selectedDate = date;
    }
  }
}
</script>

<style>
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
#app .content {
  width: 100%;
  /* max-width: 1240px; */
}
.content:not(.homepage) .header-wrapper {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

</style>
