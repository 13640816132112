<template>
    <div class="babe-item flex flex-col flex-nowrap gap-4 overflow-hidden w-full" @click="nextSlide">
        <div v-if="babe.imageNames.length > 1" class="swiper-container relative">
            <div class="swiper-wrapper">
                <div v-for="(image, index) in babe.imageNames" :key="index" class="swiper-slide h-[312px] rounded-[12px] overflow-hidden" >
                    <router-link :to="{ name: 'babeDetail', params: { id: babe.id, firstName: babe.first_name ? replaceSpaceWithDashes(babe.first_name) : 'unknown' } }" class="flex flex-col items-center w-full h-[312px]">
                        <img :src="image" :alt="babe.first_name" class="babe-img w-full object-cover object-center h-full">
                    </router-link>
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div v-else class="babe-img-wrapper flex flex-col items-center h-[312px] rounded-[12px] overflow-hidden">
            <router-link :to="{ name: 'babeDetail', params: { id: babe.id, firstName: babe.first_name ? replaceSpaceWithDashes(babe.first_name) : 'unknown' } }" class="flex flex-col items-center w-full h-[312px]"><img v-for="(image, index) in babe.imageNames" :key="index" :src="image" :alt="babe.firstName" class="babe-img w-full object-cover object-center h-full"></router-link>
        </div>
        <div class="babe-details flex flex-col flex-nowrap gap-5 ">
            <div class="babe-detail flex flex-row flex-nowrap gap-5 items-start">
                <div class="babe-det flex flex-col flex-nowrap text-left w-3/4 justify-between mr-auto">
                    <h4 class="babe-title text-black font-body-text font-semibold text-lg leading-6">
                        <router-link :to="{ name: 'babeDetail', params: { id: babe.id, firstName: babe.first_name ? replaceSpaceWithDashes(babe.first_name) : 'unknown' } }">{{ babe.first_name }}</router-link>, <span v-if="babe.dob && computeAge(babe) > 18" clas="age">{{ computeAge(babe) }}</span></h4>
                    <div class="babe-detail-item babe-race text-[#8E8E8E]">{{ babe.nationalities[0] }}</div>
                    <div class="babe-detail-item babe-availability text-[#8E8E8E]">{{ formattedDateRange }}</div>
                    <div class="babe-detail-item mt-3"><span v-if="babe.price !== null" class="babe-price font-semibold text-lg leading-normal">${{ babe.price }}</span> <span class="text-lg text-[#8E8E8E]">{{ babe.date_mode }}</span></div>
                </div>
                <div v-if="babe.reviews !== ''" class="babe-det babe-rating flex flex-row gap-1 justify-end items-center">
                    <img src="@/assets/star.svg" > <span>{{ babe.reviews }}</span>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

export default {
    name: "BabeItem",
    props: ['babe'],
    mounted() {
        if (this.babe.imageNames.length > 1) {
            this.initSwiper();
        }
    },
    computed: {
        formattedDateRange() {
            const startDate = new Date(this.babe.availability_start);
            const endDate = new Date(this.babe.availability_end);

            const startMonth = startDate.toLocaleDateString('default', { month: 'short' });
            const endMonth = endDate.toLocaleDateString('default', { month: 'short' });

            if ( startDate.getMonth() === endDate.getMonth() ) {
                return `${startMonth} ${startDate.getDate()} - ${endDate.getDate()}, ${endDate.getFullYear()}`;
            } else {
                return `${startMonth} ${startDate.getDate()} - ${endMonth} ${endDate.getDate()}, ${endDate.getFullYear()}`;
            }
        }
    },
    methods: {
        nextSlide(event) {
        // Check if the click is inside the swiper-slide
        if (event.target.classList.contains('swiper-slide')) {
            // Trigger the next slide
            this.$swiper.slideNext();
        }
        },
        initSwiper() {
            new Swiper('.swiper-container', {
                    slidesPerView: 1,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                }
            );
        },
        computeAge(babe) {
            const birthYear = new Date(babe.dob).getFullYear();
            const currentYear = new Date().getFullYear();
            const age = currentYear - birthYear;
            return age;
        },
        replaceSpaceWithDashes(str) {
            if (str == null) { // This will check for both null and undefined
                return '';
            }
            return str.replaceAll(' ', '-');
        },

    }
}
</script>

<style scoped>
    @media only screen and (min-width: 768px) {
        .babe-item {
            max-width: calc(50% - (20px * 1 / 2));
        }
    }
    @media only screen and (min-width: 1024px) {
        .babe-item {
            max-width: calc(33.33% - (20px * 2 / 3));
        }
    }
    @media only screen and (min-width: 1280px) {
        .babe-item {
            max-width: calc(25% - (20px * 3 / 4));
        }
    }
    .swiper-slide {
        height: 312px!important;
    }
</style>